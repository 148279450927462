export const environment = {
  production: false,
  //  apiUrl: 'http://localhost:3000', //Local
 // apiUrl: 'https://dashboard.getgrowthcapital.co:3000',//production
//  apiUrl: 'https://uat-api-dashboard.getgrowthcapital.co', //uat IP
 // apiUrl: 'https://prod-dashboard.getgrowthcapital.co', //gcp prod
  apiUrl: 'https://api-dashboard.getgrowthcapital.co', //prod gcp
  //apiUrl: 'http://13.127.74.47:3000' //Disaster IP

};
