import { string } from '@amcharts/amcharts4/core';
import { Config } from '../config';

const baseUrl: string = new Config().getBaseURL();
let id : number ;
let accType : string ; 
/* ========================GET APIS======================== */
export const GET_ENTITY_DETAILS: string = `${baseUrl}/company/entityDetails`,
  GET_PG_ACCOUNTS: string = `${baseUrl}/PGAccount/getPGAccounts`,
  GET_BANK_ACCOUNTS: string = `${baseUrl}/company/bankAccounts`,
  GET_ITR_DATA: string = `${baseUrl}/company/itrData`,
  GET_GSTIN: string = `${baseUrl}/company/gstin`,
  GET_MARKETING_ACCOUNTS: string = `${baseUrl}/company/marketingAccounts`,
  GET_ASSETS: string = `${baseUrl}/company/assets`,
  GET_OBLIGATIONS: string = `${baseUrl}/company/obligations`,
  ALL_APPLICATIONS: string = `${baseUrl}/application/applications`,
  ALL_PAYMENTS: string = `${baseUrl}/payment/allPayments`,
  GET_VENDORSLIST: string = `${baseUrl}/payment/getVendorList`,
  GET_DRAWDOWNREQUES_DETAILS: string = `${baseUrl}/payment/getDrawdownRequestDetails`,
  GET_DRAWDOWNREQUEST_LISTS: string = `${baseUrl}/payment/getDrawdownRequestLists`,
  ADD_DRAWDOWNREQUEST: string = `${baseUrl}/payment/addDrawdownRequest`,
  GET_BUYERLIST: string = `${baseUrl}/payment/getBuyerList`,
  ALL_VENDORS: string = `${baseUrl}/vendor/getAllVendors`,
  ALL_VENDORSDETAILS: string = `${baseUrl}/vendor/getVendorDetails`,
  // DOWNLOADVNDERDOC: string = `${baseUrl}/vendor/downloadVendorFile`,
  ALL_ADS_ACCOUNTS: string = `${baseUrl}/company/getAdsAccounts`,
  OTHER_DIGITAL_SPENDS: string = `${baseUrl}/company/getAllDigitalSpends`,
  GET_MARKETPLACE_DATA = `${baseUrl}/company/getMarketAccountDetails`,
  // UPDATE_MARKETPLACE_DATA = `${baseUrl}/company/:${id}/${accType}/removeMarketPlaceAccount`,
  GET_TOKEN_AND_CLIENT_ID: string = `${baseUrl}/company/getCsrfToken`,
  GET_STEP_STATUS: string = `${baseUrl}/company/getStatus`,
  GET_NDA_DETAILS: string = `${baseUrl}/company/getNDADetails`,
  USER_DETAILS: string = `${baseUrl}/user/userDetails`,
  SHOPIFY_ACCOUNTS: string = `${baseUrl}/shopify/shopifyAccounts`,
  GET_PAN_NAME: string = `${baseUrl}/company/getPANName`,
  ADD_PREAPPROVED_DEAL: string = `${baseUrl}/application/createPreApprovedApplication`,
  DEAL_DATA = (id: string) => {
    return `${baseUrl}/application/${id}/deal`;
  },
  REPAYMENT_DATA = (applicationId: string)=>{
    return `${baseUrl}/deal/${applicationId}/repaymentTracking`
  },
  DOWNLOAD_DOCUMENT = (applicationId:string)=>{
    return `${baseUrl}/application/${applicationId}/downloadDocuments`
  },
  FETCH_INVESTING_PARTNERS = (id:string)=>{
    return `${baseUrl}/application/${id}/investingPartner`
  },
  FETCH_DOCUMENT_DETAILS = (appId:string)=>{
    return `${baseUrl}/application/${appId}/documentDetails`
  },
  DISBURSEMENTS = (id: string) => {
    return `${baseUrl}/cardSpends/${id}/cardSpends`;
  },
  RECOVERY_DATA = (id: string) => {
    return `${baseUrl}/recovery/${id}/recoveryData`;
  },
  LEDGER_DATA = (id: string,page,count) => {
    return `${baseUrl}/recovery/${id}/ledgerData?page=${page}&count=${count}&d=false`
  },
  FUND_UTILIZATION = (id: string) => {
    return `${baseUrl}/deal/${id}/fundUtilization`;
  },

  GET_META_DATA: string = `${baseUrl}/application/metaData`,

  ESCROW_STATEMENT = (id: string) => {
    return `${baseUrl}/deal/${id}/b2bRepayment`;
  },
  GET_RESET_PWD_TOKEN = (token: string) => {
    return `${baseUrl}/user/${token}/getAuthToken`;
  },
  GET_INSIGHTS_DATA: string = `${baseUrl}/company/insightsData`,
  GET_VA_STATEMENT: string = `${baseUrl}/deal/vaStatement`,
  DOCUMENT_SIGNING_LINK = (id: string) => {
    return `${baseUrl}/application/${id}/getSigningLink`;
  },
  DOCUMENT_DOWNLOAD = (id: string) => {
    return `${baseUrl}/application/${id}/downloadSignin`;
  };


/* ========================POST APIS======================== */
export const LOGIN: string = `${baseUrl}/user/login`,
  cashfreeLogin: string = `${baseUrl}/user/cashfreeLogin`,
  ADD_ANNUAL_DOCUMENT: string = `${baseUrl}/document/addAnnualReport`,
  ADD_OBLIGATION: string = `${baseUrl}/company/addObligation`,
  ADD_ASSET: string = `${baseUrl}/company/addAsset`,
  UPLOAD_ASSET_OBLIGATION_DOCUMENT: string = `${baseUrl}/company/uploadFile`,
  ADD_ITR: string = `${baseUrl}/company/addItrDetails`,
  UPLOAD_ITR_DOC: string = `${baseUrl}/company/uploadITR`,
  ADD_PG: string = `${baseUrl}/PGAccount/addPGAccount`,
  UPDATE_PG = (id: string) => {
    return `${baseUrl}/PGAccount/${id}/updatePGAccount`;
  },
  ADD_NDA: string = `${baseUrl}/company/addNDADetails`,
  ADD_ENTITY: string = `${baseUrl}/company/addEntityDetails`,
  UPLOAD_DOCUMENT: string = `${baseUrl}/document`,
  VALIDATE_IFSC: string = `${baseUrl}/bankStatement/validateIFSC`,
  UPLOAD_BANK_STATEMENT: string = `${baseUrl}/bankStatement/uploadBankStatement`,
  ADD_BANK_STATEMENT: string = `${baseUrl}/bankStatement/addBankStatement`,
  RAISE_QUERY: string = `${baseUrl}/userQuery/addQuery`,
  ADD_VENDOR: string = `${baseUrl}/vendor/addVendor`,
  ADD_VENDORDETAILS: string = `${baseUrl}/vendor/AddVendorDetails`,
  UPLOADVNDERDOC: string = `${baseUrl}/vendor/uploadVendorDoc`,
  UPLOAD_INVOICEANDGRNFILE: string = `${baseUrl}/payment/uploadInvoiceNew`,
  DRAWDOWN_REQUESTLIST: string = `${baseUrl}/payment/getDrawdownRequestList`,
  SHOPIFY_OAUTH: string = `${baseUrl}/shopify/oAuth`,
  CONNECT_GST: string = `${baseUrl}/company/connectGst`,
  ADD_ADWORDS_ACCOUNT: string = `${baseUrl}/company/addAdwordsAccount`,
  ADD_FACEBOOK_ACCOUNT: string = `${baseUrl}/company/addFbAccount`,
  CHECK_RAZORPAY_OAUTH: string = `${baseUrl}/company/razorPayAuthentication`,
  DOWNLOAD_BANK_STATEMENT: string = `${baseUrl}/bankStatement/downloadBankStatement`,
  FORGOT_PASSWORD: string = `${baseUrl}/user/forgotPassword`,
  NEW_FORGOT_PASSWORD: string = `${baseUrl}/user/newForgotPassword`,
  VALIDATED_AUTHCODE: string = `${baseUrl}/user/authcodeVerification`,
  //SET_NEW_PWD: string = `${baseUrl}/user/authCodeResetPassword`,
  SET_NEW_PWD = (token: string) => {
    return `${baseUrl}/user/${token}/resetpassword`;
  },
  RESET_PWD: string = `${baseUrl}/user/updatePassword`,
  ADD_ADDITIONAL_USER: string = `${baseUrl}/user/addAdditionalUser`,
  LOGOUT: string = `${baseUrl}/user/logout`,
  CREATE_APPLICATION: string = `${baseUrl}/application/createNextApplication`,
  SUBMIT_APPLICATION: string = `${baseUrl}/application/completeApplication`,
  SHOPIFY: string = `${baseUrl}/shopify`,
  SHOPIFYSHOPDATA: string = `${baseUrl}/shopify/getShop`,
  SHOPIFYCUSTOMERDATA: string = `${baseUrl}/shopify/getCustomers`,
  ADD_OTHER_DIGITAL_ACC: string = `${baseUrl}/company/addDigitalSpends`,
  ADD_PAYMENT: string = `${baseUrl}/payment/addPayment`,
  PROVIDE_FEEDBACK: string = `${baseUrl}/userFeedback/addFeedback`,
  PROVIDE_OPEN_FEEDBACK: string = `${baseUrl}/userFeedback/addOpenFeedback`,
  AGREE_TERMS: string = `${baseUrl}/user/updateTnC`,
  GET_INSIGHTS_GRAPH = (type: string) => {
    return `${baseUrl}/company/insightsGraphData?type=${type}`;
  },
  CREDIT_ANALYSIS: string = `${baseUrl}/creditAnalysis/initialOffer`,
  UPDATE_STATUS: string = `${baseUrl}/creditAnalysis/updateStatus`,
  POST_FLIPKART_DATA : string = `${baseUrl}/company/addMarketPlaceAccount `;