<!-- <div class="border-end bg-color" id="sidebar-wrapper">
    <div class="sidebar-heading border-bottom "><img src="assets/img/get-growth-logo-white.png" class="img-fliud"> </div>
    <div class="list-group list-group-flush">
        <h5 class="p-3">Menu</h5>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['/main/dashboard']"> <span class="icon-03"></span><span class="menu-title"> Dashboard</span></a>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" [routerLink]="['/main/company-details']"><span class="icon-02"></span> <span class="menu-title">Company Profile</span> </a>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!"><span class="icon-01"></span> <span class="menu-title">Vendors</span></a>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!"><span class="icon-04"></span> <span class="menu-title">Events </span></a>

    </div>
</div> -->

<div class="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column" id="sidebar">
    <ul class="nav flex-column text-white w-100">
        <a [routerLink]="['/main/dashboard']" class="nav-link h3 text-white my-2 bg-none text-center" style="padding: 1.5rem 1rem;">
            <img src="assets/img/GGC__Colour_ White.png" width="180px" class="img-fliud">
        </a>

        <!-- <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!"> <span class="icon-03"></span><span class="mx-2"> Dashboard</span></a>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!"><span class="icon-02"></span> <span class="mx-2">Company Profile</span> </a>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!"><span class="icon-01"></span> <span class="mx-2">Vendors</span></a>
        <a class="list-group-item list-group-item-action list-group-item-light p-3" href="#!"><span class="icon-04"></span> <span class="mx-2">Events </span></a> -->

        <li [routerLink]="['/main/dashboard']" routerLinkActive="active">
            <a class="nav-link cursor"> <span class="icon-03"></span><span class="mx-2"> Dashboard</span></a>
        </li>
        <li [routerLink]="['/main/company-details']" routerLinkActive="active" *ngIf="isFirstApplicationCompleted" [@fadeIn]>
            <a class="nav-link cursor"><span class="icon-02"></span> <span class="mx-2">Company Details</span> </a>
        </li>
        <li [routerLink]="['/main/dashboard/youroffers']" routerLinkActive="active" *ngIf="isFirstApplicationCompleted" [@fadeIn]>
            <a class="nav-link cursor"><span class="icon-02"></span> <span class="mx-2">Issued Offers</span> </a>
        </li>
        <ng-container *ngIf=" !['RBF','fixedTerm'].includes(dealType)">
            <li routerLinkActive="active" *ngIf="!hideSideRoutes" [@fadeIn]>
                <a routerLink="/main/vendors" class="nav-link cursor"><span class="icon-01"></span> <span class="mx-2">Vendors</span></a>
            </li>
            <li routerLinkActive="active" *ngIf="!hideSideRoutes" [@fadeIn]>
                <a routerLink="/main/payments" class="nav-link cursor"><span class="icon-04"></span> <span class="mx-2">Payments </span></a>
            </li>
        </ng-container>
        

        <li routerLinkActive="active" >
            <a routerLink="/main/my-profile/add-additional-user" class="nav-link cursor"><span class="far fa-user-plus"></span> <span class="mx-2">Add User </span></a>
       
            <!-- <a routerLink="/main/my-profile/add-additional-user" class="nav-link text-body font-weight-bold px-0 iconbg">
                <i class="far fa-user-plus"></i>
               

            </a> -->
        </li>
        <li routerLinkActive="active" *ngIf="userDetails._id==='61efe8191516079e31ae24d7'">
            <a routerLink="/main/cibil" class="nav-link cursor"><span class="far fa-file-chart-line"></span><span class="mx-2">CIBIL</span></a>
        </li>
    </ul>

    <!-- <span href="#" class="nav-link h4 w-100 mb-5">
      <a href=""><i class="bx bxl-instagram-alt text-white"></i></a>
      <a href=""><i class="bx bxl-twitter px-2 text-white"></i></a>
      <a href=""><i class="bx bxl-facebook text-white"></i></a>
    </span> -->
</div>