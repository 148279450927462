import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, interval, Subscription } from 'rxjs';
import { throttle } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idleSubject = new Subject<boolean>();
  private timeout = 1800; // timer to make idle
  private lastActivity?: Date;
  private idleCheckInterval = 10; //seconds
  private idleSubscription?: Subscription;

  public isLoggedin= new BehaviorSubject<boolean>(false);
  loginState$ = this.isLoggedin.asObservable();

  constructor() {
    this.resetTimer();
    this.startWatching();
    this.logingStatus();
  }

  get idleState(): Observable<boolean> {
    return this.idleSubject.asObservable();
  }

  logingStatus(){
    const token = localStorage.getItem('userToken');
    if(token) this.isLoggedin.next(true)
    else this.isLoggedin.next(false)
  }

  private startWatching(){
    this.idleSubscription = interval(this.idleCheckInterval* 1000).pipe(throttle(()=> interval(1000))).subscribe(() => {
      const now = new Date();
      if(now.getTime() - this.lastActivity?.getTime() > this.timeout * 1000){
        this.idleSubject.next(true);
      }
    })
  }

  resetTimer(){
    this.lastActivity = new Date();
    this.idleSubject.next(false);
  }

  stopWatching(){
    if(this.idleSubscription){
      this.idleSubscription.unsubscribe();
    }
  }
}
