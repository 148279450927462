import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Config } from '../config';
import { Asset, AssetResponse } from '../interfaces/assets.interface';
import { Obligation, ObligationResponse } from '../interfaces/obligation.interface';
import { AddAnnualDocument, AnnualDocumentResponse } from '../interfaces/annualReport.interface';
import { ApiResponse } from '../interfaces/apiResponse-interface';
import * as api from "./all.api";
import { Entity, EntityResponse, NdaResponse } from '../interfaces/entity.interface';
import { PGResponse } from '../interfaces/pg.interface';
import { ITR, ItrResponse } from '../interfaces/itr.interface';
import { AddBank, IFSCValidateResponse, StatementUploadResponse } from '../interfaces/bank.interface';
import { MarketingResponse } from '../interfaces/marketing.interface';
import { StepStatusResponse } from '../interfaces/form-steps.interface';
import { DocumentUploadResponse, OtherDigitalAccount } from '../interfaces/document.interface';
import { Socket } from 'ngx-socket-io';

const baseUrl: string = new Config().getBaseURL()

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  GSTUpdateRequest = this.socket.fromEvent<any>("userGST");

  /* ===============================share replay for get apis=============================== */

  entityDetails$ = this.http.get<EntityResponse>(api.GET_ENTITY_DETAILS)

  pgAccounts$ = this.http.get<PGResponse>(api.GET_PG_ACCOUNTS)

  bankAccounts$ = this.http.get(api.GET_BANK_ACCOUNTS)

  itrData$ = this.http.get<ItrResponse>(api.GET_ITR_DATA)

  marketingAccounts$ = this.http.get(api.GET_MARKETING_ACCOUNTS)
  
  marketplaceAccounts$ = this.http.get(api.GET_MARKETPLACE_DATA)

  // removeMarketPlaceAccounts$ = this.http.get(api.UPDATE_MARKETPLACE_DATA)

  allAssets$ = this.http.get<AssetResponse>(api.GET_ASSETS)

  allObligations$ = this.http.get<ObligationResponse>(api.GET_OBLIGATIONS)

  allAdsAccounts$ = this.http.get<MarketingResponse>(api.ALL_ADS_ACCOUNTS)

  otherDigitalSpends$ = this.http.get<any>(api.OTHER_DIGITAL_SPENDS)

  csrfTokenAndClientID$ = this.http.get<any>(api.GET_TOKEN_AND_CLIENT_ID)

  stepStatus$ = this.http.get<StepStatusResponse>(api.GET_STEP_STATUS)

  ndaDetails$ = this.http.get<NdaResponse>(api.GET_NDA_DETAILS)
  // getAllMarketPlaceAccounts$: Observable<any>;

  /* ===============================share replay for get apis=============================== */

  constructor(private http: HttpClient, private socket: Socket) { }

  /* =========================================GET API'S============================================ */
  getEntityDetails$(): Observable<EntityResponse> {
    return this.entityDetails$
  }

  getAllPGAccounts$(): Observable<PGResponse> {
    return this.pgAccounts$
  }

  getAllBankAccounts(): Observable<any> {
    return this.bankAccounts$
  }

  getAllITR$(): Observable<ItrResponse> {
    return this.itrData$
  }

  getAllGSTINS(page: any, count: any = '5'): Observable<any> {
    return this.http.get(api.GET_GSTIN, { params: { page, count } })
  }

  getAllMarketingAccounts(): Observable<any> {
    return this.marketingAccounts$
  }

  getAllMarketPlaceAccounts() : Observable<any> {
    return this.marketplaceAccounts$ 
  }

  reomveAllmarketPlaceAccounts(id : string , accType : string) : Observable<any> {
    return this.http.get(`${baseUrl}/company/:${id}/:${accType}/removeMarketPlaceAccount`)
  }


  getAllAssets$(): Observable<AssetResponse> {
    return this.allAssets$
  }

  getAllObligations$(): Observable<ObligationResponse> {
    return this.allObligations$
  }

  getAllAdsAccounts$(): Observable<MarketingResponse> {
    return this.allAdsAccounts$
  }

  getAllOtherDigitalSpends$(): Observable<any> {
    return this.otherDigitalSpends$
  }

  getTokenAndClientID$(): Observable<any> {
    return this.csrfTokenAndClientID$
  }

  getFormStepsStatus$(): Observable<StepStatusResponse> {
    return this.stepStatus$
  }

  getNdaDetails$(): Observable<NdaResponse> {
    return this.ndaDetails$
  }

  fetchShopifyAccounts$(): Observable<any> {
    return this.http.get<any>(api.SHOPIFY_ACCOUNTS)
  }


  getAnnualDocumentsByType$(docType: string = 'mis'): Observable<AnnualDocumentResponse> {
    return this.http.get<AnnualDocumentResponse>(`${baseUrl}/document/${docType}/getDocuments`)
  }

  getKycDocumentsGem$(loanApplicationId: string) {
    return this.http.get(`${baseUrl}/document/${loanApplicationId}/getKycDocumentsGem`)
  }

  resendKycLink(data: object) {
    return this.http.post(`https://uat-api-onevision.getgrowthcapital.co/v1/digikycGS/resentlink`, data)
  }
  /* =========================================GET API'S END============================================ */



  /* =========================================POST API'S============================================ */
  sendAddEntityRequest$(data): Observable<EntityResponse> {
    return this.http.post<EntityResponse>(api.ADD_ENTITY, data)
  }

  sendAddNdaRequest(data: any): Observable<any> {
    return this.http.post(api.ADD_NDA, data)
  }

  sendAddPGRequest$(data: any): Observable<PGResponse> {
    return this.http.post<PGResponse>(api.ADD_PG, { data })
  }

  sendUpdatePGRequest$(id: string, data: any): Observable<any> {
    return this.http.post<any>(api.UPDATE_PG(id), { data })
  }

  sendUploadITRToS3Request$(file): Observable<any> {
    return this.http.post(api.UPLOAD_ITR_DOC, file)
  }

  sendAddITRRequest$(data: any): Observable<ItrResponse> {
    return this.http.post<ItrResponse>(api.ADD_ITR, data)
  }

  uploadAssetOrObligationDocument$ = (file): Observable<ApiResponse> => {
    return this.http.post<ApiResponse>(api.UPLOAD_ASSET_OBLIGATION_DOCUMENT, file)
  }

  sendAddAssetRequest$ = (data: any): Observable<AssetResponse> => {
    return this.http.post<AssetResponse>(api.ADD_ASSET, data)
  }

  sendAddObligationRequest$ = (data: Obligation): Observable<ObligationResponse> => {
    return this.http.post<ObligationResponse>(api.ADD_OBLIGATION, data)
  }

  uploadAnnualDocument$ = (file, docType: string): Observable<ApiResponse> => {
    return this.http.post<ApiResponse>(`${api.UPLOAD_DOCUMENT}/${docType}/uploadAnnualReport`, file)
  }

  uploadCompanyDocument$ = (file, docType: string, filename: string): Observable<ApiResponse> => {
    return this.http.post<ApiResponse>(`${api.UPLOAD_DOCUMENT}/${docType}/uploadDocument`, file, { params: { name: filename } })
  }

  sendAddAnnualDocumentRequest$ = (data: AddAnnualDocument): Observable<AnnualDocumentResponse> => {
    return this.http.post<AnnualDocumentResponse>(api.ADD_ANNUAL_DOCUMENT, data)
  }

  validateIFSC$ = (data: string): Observable<IFSCValidateResponse> => {
    return this.http.post<IFSCValidateResponse>(api.VALIDATE_IFSC, { ifscCode: data })
  }

  uploadBankStatement$ = (file): Observable<StatementUploadResponse> => {
    return this.http.post<StatementUploadResponse>(api.UPLOAD_BANK_STATEMENT, file)
  }

  addBankStatement$ = (data: AddBank): Observable<StatementUploadResponse> => {
    return this.http.post<StatementUploadResponse>(api.ADD_BANK_STATEMENT, data)
  }

  checkShopifyOAuth$ = (data: any): Observable<any> => {
    return this.http.post<any>(api.SHOPIFY_OAUTH, data)
  }

  sendRequestToAddAdwordAccount$ = (code: string): Observable<any> => {
    return this.http.post<any>(api.ADD_ADWORDS_ACCOUNT, { code })
  }

  sendRequestToAddFacebookAccount$ = (code: string): Observable<any> => {
    return this.http.post<any>(api.ADD_FACEBOOK_ACCOUNT, { code })
  }

  checkGSTAndConnect$ = (data: any): Observable<any> => {
    return this.http.post<any>(api.CONNECT_GST, data)
  }

  checkRazorpayOAuth$ = (data: any): Observable<any> => {
    return this.http.post<any>(api.CHECK_RAZORPAY_OAUTH, data)
  }

  downloadBankStatementFromS3$ = (statementUrl: string): Observable<any> => {
    return this.http.post<any>(api.DOWNLOAD_BANK_STATEMENT, { statementUrl })
  }

  deleteShopifyData$ = (accountId: string): Observable<any> => {
    return this.http.post<any>(`${api.SHOPIFY}/${accountId}/ShopifyDataDelete`, {})
  }

  uninstallShopifyShop$ = (accountId: string): Observable<any> => {
    return this.http.post<any>(`${api.SHOPIFY}/${accountId}/shopifyUninstallation`, {})
  }

  getShopifyShopData$ = (): Observable<any> => {
    return this.http.get<any>(`${api.SHOPIFYSHOPDATA}`)
  }

  getShopifyCustomersData$ = (): Observable<any> => {
    return this.http.get<any>(`${api.SHOPIFYCUSTOMERDATA}`)
  }

  uploadDocument$ = (file, name): Observable<DocumentUploadResponse> => {
    return this.http.post<DocumentUploadResponse>(`${api.UPLOAD_DOCUMENT}/INVOICE/uploadDocument`, file, { params: { name } })
  }

  addOtherDigitalAccount$ = (data: OtherDigitalAccount): Observable<any> => {
    return this.http.post<any>(api.ADD_OTHER_DIGITAL_ACC, data)
  }

  addPayment$ = (data: any): Observable<any> => {
    return this.http.post<any>(api.ADD_PAYMENT, data)
  }

  fetchPanName$(): Observable<any> {
    return this.http.get<any>(api.GET_PAN_NAME)
  }

  fetchClientInsights$(): Observable<any> {
    return this.http.get<any>(api.GET_INSIGHTS_DATA)
  }

  fetchClientInsightsGraph$(type: string, data: any): Observable<any> {
    return this.http.post<any>(api.GET_INSIGHTS_GRAPH(type),data)
  }
  

  addMarketPlaceAccounts$(data: any): Observable<any>{
    return this.http.post<any>(api.POST_FLIPKART_DATA,data);
  }

  getTokenForAdYogi(): Observable<any> {
    return this.http.get(`${baseUrl}/adYogi/adYogiTokenGenerate`)
  }


  /* =========================================POST API'S END============================================ */
}
