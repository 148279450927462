import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { IdleService } from './services/idle.service';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
@Component({
  selector: 'app-root',
  template: `
    <div (mousemove)="onUserAction()" (click)="onUserAction()" (keypress)="onUserAction()">
      <router-outlet>
        <ngx-ui-loader></ngx-ui-loader>
        <app-raise-query></app-raise-query>
      </router-outlet>
    </div>
  `
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'GetGrowth Capital ';
  private isIdleSunscription?: Subscription;

  constructor(private idleService: IdleService, private authService: AuthService, private router: Router, private store: Store){}

  ngOnInit(): void {
    this.idleService.loginState$.subscribe((login) => {
      console.log('login222---->',login);
      this.isIdleSunscription =this.idleService.idleState.subscribe((isIdle) => {
        if(isIdle) {
          console.log('user is idle!');
          this.authService.logout().subscribe(
          res => this.clearStateAndNavigate(),
          err => this.clearStateAndNavigate()
        );}
        else console.log('user is active!')
      })
    })
  }

  clearStateAndNavigate() {
    localStorage.clear()
    this.store.reset(null)
    this.router.navigate(['/'])
  }

  ngOnDestroy(): void {
    if(this.isIdleSunscription) this.isIdleSunscription.unsubscribe();
  }

  onUserAction(){
    this.idleService.resetTimer();
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event: any) {
  //   // Clear local storage
  //   localStorage.clear();
  // }

  // @HostListener('window:unload', ['$event'])
  // unloadHandler(event: any) {
  //   // Clear local storage
  //   localStorage.clear();
  // }
}
