import { trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { fadeIn } from 'src/app/animation/fade-in.animation';
import { ApplicationResponse, Applications } from 'src/app/interfaces/application.interface';
import { ApplicationService } from 'src/app/services/application.service';
import { FetchAllApplications } from 'src/app/store/actions/application.action';
import { ApplicationsState } from 'src/app/store/state/application.state';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [ trigger('fadeIn', fadeIn()) ]
})
export class SidebarComponent implements OnInit {
  
  @Select(ApplicationsState.isApplicationsLoaded) isLoaded$: Observable<boolean>
  @Select(ApplicationsState.getApplications) applicationsListing$: Observable<any>
  subscriptions$:       Subscription[] = []
  isFirstApplication: boolean = true
  isFirstApplicationCompleted: boolean = false
  firstApplicationStatus: string = ''
  dealType: string = ''
  hideSideRoutes: boolean = true
  status: boolean = false;
  userDetails:any={}
  clickEvent(){
      this.status = !this.status;       
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.toggleSidebarBasedOnScreenSize()
  }

  constructor( private application: ApplicationService, private route: ActivatedRoute, private store: Store,private sharedService: SharedDataService ) { 
    this.hideSideRoutes = route.snapshot['_routerState'].url.includes('new-application') ? true : false
  }

  ngOnInit(): void {
    this.userDetails=JSON.parse(localStorage.getItem('user'))

    console.log("USER_ID",this.userDetails['_id'])
    this.checkApplicationsInState()
    this.toggleSidebarBasedOnScreenSize()

    var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    this.sharedService.dealType$.subscribe(dType => {
      console.log('DealType==>',dType)
      this.dealType = dType
    })
    menu_btn.addEventListener("click", () => {
      sidebar.classList.toggle("active-nav");
      container.classList.toggle("active-cont");
    });
  }


  checkApplicationsInState(){
    let sub = this.isLoaded$.subscribe(loaded=>{
      if( loaded ) this.getApplicationFromState()
      else this.loadAllApplications()
    })
    this.subscriptions$.push(sub)
  }

  getApplicationFromState(){
    let sub = this.applicationsListing$.subscribe((data: Applications)=>{
      this.isFirstApplication = data.isFirstApplication
      this.firstApplicationStatus = data.applications[0]['status']
      this.isFirstApplicationCompleted = data.isFirstApplicationCompleted
      this.checkForUpdates()
    })
    this.subscriptions$.push(sub)
  }

  loadAllApplications(){
    let sub = this.store.dispatch( new FetchAllApplications() )
    .subscribe(
      data=> this.getApplicationFromState(),
      error=>{
        this.isFirstApplication = true
        this.firstApplicationStatus = 'pending' 
        this.checkForUpdates()
      }
    );
    this.subscriptions$.push(sub)
  }


  checkForUpdates(){
    if( this.isFirstApplication && ['pending', 'inprocess', 'completed' ].includes(this.firstApplicationStatus) ){
      this.hideSideRoutes = true
    }
    else this.hideSideRoutes = false
  }

  toggleSidebarBasedOnScreenSize(){
    const screenWidth = window.innerWidth;
    if( screenWidth < 768 ){
      document.getElementById('sidebar').classList.remove('active-nav')
    }
  }



}
