import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private dealTypeSource = new BehaviorSubject<string>('');
  dealType$ = this.dealTypeSource.asObservable();

  updateApplicationId(dealType: string) {
    this.dealTypeSource.next(dealType);
  }
  constructor() { }
}
