import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {map} from 'rxjs/operators'
import { Observable, of } from 'rxjs';
import { Config } from '../config';
import { LOGIN, LOGOUT, USER_DETAILS, FORGOT_PASSWORD, RESET_PWD, SET_NEW_PWD, VALIDATED_AUTHCODE, AGREE_TERMS, NEW_FORGOT_PASSWORD, GET_RESET_PWD_TOKEN,ADD_ADDITIONAL_USER, cashfreeLogin} from './all.api'
import { Login, Logout, UserProfile } from '../interfaces/auth.interface'
const basUrl: string = new Config().getBaseURL()

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private store: Store, private router: Router) {

    window.addEventListener('beforeunload', (event) => {
      // Check if sessionStorage or other custom logic can indicate a page reload vs. close
      const isReloading = performance.getEntriesByType("navigation")[0]?.entryType === 'navigation'

      if (!isReloading) {
        this.logout();
      }
  })
  }

  login({ email, password }): Observable<Login> {
    return this.http.post<Login>(LOGIN, { email, password })
  }

  cashfreeLogin(token:string){
    // let headers =  {Authorization: `Bearer ${token}`}
    // const header = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer ${token}`
    // });
    // console.log('token::::', token)
    // const header = new HttpHeaders()
    // header.set('Authorization', token);

    return this.http.get(cashfreeLogin)
  }

  logout(): Observable<Logout> {
    localStorage.clear();
    return this.http.post<Logout>(LOGOUT, {})
  }

  fetchUserData(): Observable<UserProfile> {
    return this.http.get<UserProfile>(USER_DETAILS)
  }

  isLoggedIn() {
    return of(!!localStorage.getItem('userToken'))
  }

  forgotPwd({ email }): Observable<any> {
    return this.http.post(FORGOT_PASSWORD, { email })
  }

  newforgotPwd({ email }): Observable<any> {
    return this.http.post(NEW_FORGOT_PASSWORD, { email })
  }
  getResetPwdToken(token: any): Observable<any> {
    return this.http.get(GET_RESET_PWD_TOKEN(token))
  }

  validateAuthCode({ authCode }): Observable<any> {
    return this.http.post(VALIDATED_AUTHCODE, { authCode })
  }

  setNewPwd({ password, confirmPassword, token }): Observable<any> {
    return this.http.post(SET_NEW_PWD(token), { password, confirmPassword })
  }

  resetPwd({ oldPass, newPass, confirmPass }): Observable<any> {
    return this.http.post(RESET_PWD, { oldPass, newPass, confirmPass })
  }

  addAdditionalUser(payload:any): Observable<any> {
    return this.http.post(ADD_ADDITIONAL_USER, payload)
  }

  agreeTnc({ termsAndConditions }): Observable<any> {
    return this.http.post(AGREE_TERMS, { termsAndConditions })
  }

  fetchUserIP(): Observable<UserProfile> {
    return this.http.get<any>(`https://jsonip.com`)
  }

  activateNewUser(payload:any, resetToken: string): Observable<any>{
    console.log(payload)
    return this.http.post(`${basUrl}/user/setNewPassword/${resetToken}`, payload)
  }
}
