import { environment } from "../environments/environment";
export class Config {
    baseUrl: string
    serverKey: string
    serverSecret: string
    username: string
    password: string

    constructor() {
        this.baseUrl = environment.apiUrl
        // this.serverKey = environment.serverkey
        // this.serverSecret = environment.serversecret
        // this.username = environment.username
        // this.password = environment.password
    }

    getBaseURL() {
        return this.baseUrl
    }

    getSocketUrl() {
        return environment.apiUrl
    }

    getserverKey() {
        return this.serverKey
    }

    getserverSecret() {
        return this.serverSecret
    }

    getUsername() {
        return this.username
    }

    getPassword() {
        return this.password
    }

}
